<script>


export default {
  render() {
    return this.$scopedSlots.default({
      validate: this.validate
    })
  },
  props: {
    formErrors: Object
  },


  data: () => ({
  }),

  methods: {
    validate(data) {
      if(data.notes) return this.$emit('valid', data)
      this.formErrors.invalidField('notes', 'Required')
      this.$notify({type: 'error', text: 'Notes required with not recomended payment'})
    },
  }
}
</script>